body {
  margin: 0;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
}

#root {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  width: auto;
  height: 100vh;
  font-family: cursive;
  --backgroundColor: #111410bd;
  --hoverColor: #888888de;
}
